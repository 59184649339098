
$.expr[":"].Contains = $.expr.createPseudo(function(arg) {
    return function( elem ) {
        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});

function afterAjaxRenderSearch(searchString) {
    $(".searchwp-live-search-result a:Contains('" + searchString + "')").html(function(_, html) {
        var re = new RegExp('(' + searchString + ')', "gi");
        return html.replace(re, '<span class="highlighted">$1</span>');
    }); 
    $(".search-more-results").off("click");
    $(".search-more-results").on("click", function() {
        $(".search-bar--row form").submit();
    });

    // Email guide
    var guideKeywords = ["email", "e-mail", "opsætning", "guide", "hjælp", "outlook", "mac mail", "mail", "exchange"];
    if( $.inArray( searchString, guideKeywords ) != -1 ){
        $(".email-guide").show();
    }
}

$(document).ready(function() {
    $("#search").focus();
    $(".search-bar--search").on("click", function() {
        $(".search-bar--row form").submit();
    });
});

var donottabindex;
/* Keyboard shortcuts for easy search */
$("#search").keydown(function(e) {
    if ( e.which == 40 || e.wich == 38 ) {
        $(".searchwp-live-search-result:first-child()").addClass("search-focus").find("a").focus();
        donottabindex = true;
        e.preventDefault();
    }
});
$(document).keydown(function(e) {
    if( $(".search-focus").length > 0 ) {
        if( donottabindex !== true) {
            if ( e.which == 38) {
                $(".search-focus").removeClass("search-focus").prev().addClass("search-focus").find("a").focus();
                secureAlwaysTab("up");
                e.preventDefault();
            }
            if ( e.which == 40) {
                $(".search-focus").removeClass("search-focus").next().addClass("search-focus").find("a").focus();
                secureAlwaysTab("down");
                e.preventDefault();
            }
        } else {
            donottabindex = false;
        }
    }
});
function secureAlwaysTab(direction) {
    // Secure that there always is a new item when useing arrows
    if( $(".search-focus").length === 0 || $(".search-focus").hasClass("script")) {
        var dirselector;
        if(direction == "up") {
            if( $(".search-more-results").length > 0 ) {
                dirselector = $(".search-more-results");
            } else {
                dirselector = $(".searchwp-live-search-result").last();
            }
        } else {
            dirselector = $(".searchwp-live-search-result").first();
        }
        dirselector.addClass("search-focus").find("a").focus();
    }
}