$.selectedProduct = {};
$.selectedType = {};

$( ".step1 select, .step4 select" ).prop("selectedIndex", 0);

$( ".step1 select" ).on( "change", function() {
    $.selectedProduct = this.value;
    $( ".step-forward" ).hide();
    $( ".step-forward" ).find( "input" ).prop( "checked", false);
    if ( this.value !== "" ) {
        $(".step2").show();
    }
});
$( ".step2 input" ).on( "change", function() {
    $( ".step4 header, .step3, .step4 .guide-group" ).hide();
    $( ".step3" ).find( "input" ).prop( "checked", false );
    if ( this.value === "computer" ) {
        $( ".step3" ).show();
    } else if ( this.value === "smartphone" ) {
        $.selectedType = "smartphone";
        $( ".step4, .step4 .mobile" ).show();
        $( ".step4 .guide-group[data-groupname='" + $.selectedProduct + "_" + $.selectedType + "']" ).show();
    }
});
$( ".step3 input" ).on( "change", function() {
    $.selectedType = this.value;
    $( ".step4 .guide-group" ).hide();
    $( ".step4, .step4 header.computer" ).show();
    $( ".step4 .guide-group[data-groupname='" + $.selectedProduct + "_" + $.selectedType + "']" ).show();
});
$( ".step4 select" ).on( "change", function() {
    $(".guide-content").addClass("redirect-loading");
    var link = this.value;
    location.href = link;
});