$(".global-fsmo .smo").each(function() {
    var container = $(this);
    var link = container.find("a").attr("href");
    container.find("a").removeAttr("href");
    container.on("click", function() {
        window.open(link);
    });
});

setTimeout(function() {
    var isonline = $("#global-chat .online").length;
    if( isonline >= 1 ) {
        $("#global-chat .ddchat p").text("Chat med os");
        $("#global-chat").fadeIn();
    }
}, 2000);